module controllers {
    export module tariff {
        export class tariffBookDownloadCtrl {

            static $inject = [
                '$scope',
                '$rootScope',
                'generalService',
                '$q',
                '$uibModal',
                'entityService',
                'bsLoadingOverlayService',
                'tariffCountryService',
                'hsCodeService',
                '$timeout',
                'menuService',
                '$state'
            ];

            IsLoading: boolean = false;
            selectedCountry: interfaces.applicationcore.IDropdownModel;
            simulationDate: moment.Moment = moment.utc();
            selectedLocaleCode: string;
            nationalMeasureCode: string;
            tariffLocales: interfaces.tariff.ITariffBookLocale[];
            isLoading: boolean = false;

            

            constructor(
                private $scope: ng.IScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private entityService: interfaces.applicationcore.IEntityService,
                private bsLoadingOverlayService,
                private tariffCountryService: interfaces.tariff.ITariffCountryService,
                private hsCodeService: interfaces.tariff.IHsCodeService,
                public $timeout: ng.ITimeoutService,
                private menuService: interfaces.applicationcore.IMenuService,
                private $state: ng.ui.IStateService,
            ) {
                
                this.nationalMeasureCode = "";
                this.selectedLocaleCode = "en-ZA";

                var promises: ng.IPromise<any>[] = [];

                var countryPromise = entityService.getEntityCountry().get({ entityId: 0 }, (result: interfaces.applicationcore.ICountry) => {
                    this.selectedCountry = <interfaces.applicationcore.IDropdownModel>{
                        Id: result.Id,
                        Code: result.Code,
                        Description: result.Description,
                        Display: result.Code + " - " + result.Description,
                        Selected: true
                    };
                }).$promise;

                var localePromise = hsCodeService.getTariffBookLocales().query({}, (resultList) => {
                    this.tariffLocales = resultList;
                    this.selectedLocaleCode = "en-za";
                }).$promise;

                promises.push(countryPromise);
                promises.push(localePromise);

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'tariff.lookup.download'
                }, () => {
                    return $q.all(promises);
                });
            }

            download_click() {
                this.IsLoading = true;

                var stringSimulationDate = this.simulationDate.format("YYYYMMDDTHH:mm");

                this.hsCodeService.getTariffBookExcel(this.selectedCountry.Id, stringSimulationDate, this.selectedLocaleCode, this.nationalMeasureCode).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
                
            }

            loadCountries() {
                return this.tariffCountryService.getDropdownList().query().$promise;
            }
        };

        angular.module("app").controller("tariffBookDownloadCtrl", controllers.tariff.tariffBookDownloadCtrl);
    }
}